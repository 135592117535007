import React from 'react';
import './home.css';  // Assuming you'll use the updated CSS

function Home() {
  return (
    <div className="home-container">
      <div className="content">
        <h1 className="title">System Under Maintenance</h1>
        <h3 className="message">The monkeys and llamas are working hard on it.</h3>
        <h3 className="message">"Code:871"</h3>
      </div>
    </div>
  );
}

export default Home;
